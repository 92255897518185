












import Vue from "vue";
import PageHeading from "../components/PageHeading.vue";

export default Vue.extend({
  name: "NotFound",
  components: { PageHeading },
});
